<template>
  <nav
    :aria-labelledby="accountBarTitleId"
    class="account-bar-container"
    :name="$breakpoint.upToDesktop() ? 'disable-scroll-padding' : undefined"
  >
    <span :id="accountBarTitleId" aria-hidden="true" class="visually-hidden">
      Account Menü
    </span>

    <div v-if="$breakpoint.upToDesktop()" class="account-bar-spacer" />

    <ul class="account-bar__list">
      <li v-if="$breakpoint.upToDesktop()" class="account-bar__list-item">
        <a
          :href="$brand.isFonic() ? 'https://www.fonic.de' : '/'"
          class="accountbar_logo-link"
        >
          <Icon
            name="logo-mobile"
            class="logo-mobile"
            height="45"
            :width="$brand.isFonic() ? '88' : '78'"
          />
          <span class="visually-hidden">{{
            `${$brand.name} - Startseite`
          }}</span>
        </a>
      </li>

      <li
        v-if="$breakpoint.fromDesktop() && !standalone"
        class="account-bar__list-item high-contrast separate"
      >
        <ThemeToggle
          v-model="isHighContrastModeActive"
          class="account-bar__link"
          data-cy="toggle-theme-switch"
          :tabindex="tabIndex"
          @click="toggleTheme"
        />
      </li>

      <li v-if="!standalone" class="account-bar__list-item first">
        <router-link
          v-if="loggedIn"
          :tabindex="tabIndex"
          class="account-bar__link"
          :to="{ name: 'SelfcareHome' }"
          data-cy="accountbarLoggedInUser"
        >
          <Icon
            name="customer"
            height="11"
            up="3"
            :alt-text="`${$brand.name} Selfcare`"
          />
          <span class="visually-hidden">Mein {{ $brand.name }}</span>
          {{ msisdn }}
        </router-link>

        <router-link
          v-else
          :tabindex="tabIndex"
          class="account-bar__link"
          :to="{ name: 'Login' }"
        >
          <Icon name="login" height="15" up="2" />
          Mein {{ $brand.name }}
        </router-link>
      </li>

      <!-- /////////// -->

      <li
        v-if="$breakpoint.fromDesktop() && !standalone"
        class="account-bar__list-item"
      >
        <ButtonComponent
          v-if="loggedIn"
          data-cy="logout-button"
          button-type="look-like-link"
          :tabindex="tabIndex"
          :clickhandler="logout"
          @click.stop
        >
          <div class="account-bar__link">
            <Icon name="logout" height="15" up="4" />
            <span class="visually-hidden">Logout</span>
          </div>
        </ButtonComponent>
      </li>

      <li v-if="!standalone" class="account-bar__list-item">
        <router-link
          v-if="!loggedIn && !$brand.isFonic()"
          :tabindex="tabIndex"
          class="account-bar__link"
          :to="{ name: 'PortalServiceHome' }"
        >
          <Icon name="help" height="15" up="2" />
          FAQs
        </router-link>
      </li>

      <!-- /////////// -->

      <li v-if="!standalone" class="account-bar__list-item last">
        <ButtonComponent
          :id="helpButtonId"
          :tabindex="tabIndex"
          button-type="look-like-link"
          data-cy="account_bar-help"
          :clickhandler="
            () => $bus.emit('open-solution-manager', undefined, helpButtonId)
          "
          @click.stop
        >
          <div
            class="account-bar__link help"
            :class="{
              'last--mobile': $breakpoint.upToDesktop() && !$brand.isFonic()
            }"
          >
            <Icon name="magnifying-glass" height="15" up="2" />
            <span v-if="$brand.isFonic()"> Hilfe </span>
            <span v-else> Suche </span>
          </div>
        </ButtonComponent>
      </li>
      <!-- /////////// -->

      <li
        v-if="$breakpoint.upToDesktop() && !standalone && $brand.isFonic()"
        class="account-bar__list-item"
      >
        <ButtonComponent
          id="mobileMenuToggelButton"
          class="hamburger"
          :clickhandler="toggleMobileNavigation"
          button-type="look-like-link"
          :aria-expanded="mobileMenuOpen ? true : undefined"
          aria-haspopup="menu"
          @click.stop
        >
          <Icon name="hamburger" height="25" />
          <span class="visually-hidden">Menü</span>
        </ButtonComponent>
      </li>
    </ul>
  </nav>
</template>

<script>
import { DARK } from 'src/components/common/settings/themes.js'
import { mapState } from 'vuex'
import { uniqueId } from 'src/lib/helpers'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Icon from 'src/components/common/icon/icon.vue'
import ThemeToggle from 'src/components/common/input/theme_toggle.vue'

export default {
  name: 'AccountBar',
  components: {
    ThemeToggle,
    ButtonComponent,
    Icon
  },
  props: {
    standalone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      accountBarTitleId: 'account-bar-' + uniqueId(),
      helpButtonId: 'help-button-' + uniqueId()
    }
  },
  computed: {
    tabIndex: function () {
      return this.$breakpoint.fromDesktop() ? 1 : undefined
    },
    ...mapState({
      msisdn: state => state.authentication.msisdn || 'Eingeloggt',
      loggedIn: state => state.authentication.loggedIn || false,
      theme: state => state.settings.theme,
      mobileMenuOpen: state => state.accountBar.mobileMenuOpen
    }),
    isHighContrastModeActive() {
      return this.theme === DARK
    }
  },
  methods: {
    toggleTheme() {
      this.$store.dispatch('settings/toggleTheme')
    },
    logout() {
      this.$store.dispatch('authentication/logout')
    },
    toggleMobileNavigation() {
      if (this.mobileMenuOpen) {
        this.$store.commit('accountBar/closeMobileMenu')
      } else {
        this.$store.commit('accountBar/openMobileMenu')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.account-bar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: var(--account-bar-shadow);
  z-index: 25;

  @include breakpoint($from-desktop) {
    position: relative;
    justify-content: flex-end;
    box-shadow: none;
  }

  .hamburger {
    color: var(--root-color);
    padding: 5px;
    margin: 5px;
  }
}

.account-bar__list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;

  @include breakpoint($up-to-desktop) {
    background-color: var(--background-primary);
  }

  @include breakpoint($from-desktop) {
    color: var(--root-color);
    flex-grow: 0;
  }
}

.account-bar__link {
  font-size: 0.8rem;
  line-height: 20px;
  padding-top: 2px;
  color: var(--root-color);
  background-color: var(--background-primary);
  text-decoration: none;

  @include breakpoint($from-desktop) {
    padding: 1px 12px 1px 14px;
    margin: 1px 4px 1px 1px;
  }
}

.account-bar__list-item {
  display: flex;
  background-color: var(--background-primary);
  position: relative;

  @include breakpoint($from-desktop) {
    padding: 4px 0;
    max-height: 32px;

    &:not(.separate, :last-child)::after {
      content: '';
      position: absolute;
      height: 20px;
      background-color: var(--root-color);
      width: 1px;
      right: 0;
      top: 5px;
    }

    &.first {
      border-bottom-left-radius: 5px;

      .account-bar__link {
        margin-left: 5px;
        padding-left: 10px;
      }
    }

    &.last {
      border-bottom-right-radius: 5px;

      .account-bar__link {
        margin-right: 5px;
        padding-right: 10px;
      }
    }
  }
}

.account-bar__link.help:focus {
  line-height: 20px;
}

.accountbar_logo-link {
  margin-left: 4px;
}

.separate {
  @include breakpoint($from-desktop) {
    margin-right: 25px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.high-contrast {
  .icon {
    color: var(--root-color);
    margin-right: 5px;
  }

  @include breakpoint($from-desktop) {
    .account-bar__link {
      padding-left: 12px;
    }
  }
}

.last--mobile {
  margin-right: 15px;
}
</style>
